// import logo from './logo.svg';
// import './App.css';
//
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
//
// export default App;

// App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
	const [htmlContent, setHtmlContent] = useState('');

	useEffect(() => {
		const fetchHtmlContent = async () => {
			try {
				const response = await fetch('https://staging.products.tallyfy.com/', {headers: {
						'CF-Access-Client-Id': '8b735de30a1810e5040b8824772d66d7.access',
						'CF-Access-Client-Secret': 'a66ea2cc1c9274a255dadaf5f700de47cf3163a4722632d84b58a0bb9a2982e8',
					}});
				// setHtmlContent(response.data.replaceAll("https://tallyfy.com", "http://localhost:3000"));
				setHtmlContent(response.data);
			} catch (error) {
				console.error(error);
			}
		};

		fetchHtmlContent();
	}, []);

	return (
		<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
	);
};

export default App;